import React from 'react'
import Img from 'gatsby-image'

import { Wrapper, Content, Text } from './Section.style';
import WidthWrapper from '../WidthWrapper';
import MarkdownContent from '../Markdown';

export default function Section({ children, background, title, image, centered, reverse, id, content }) {
  return (
    <Wrapper background={background} id={id}>
      <WidthWrapper>
        <Content reverse={reverse}>
          <Text centered={centered}>
            {title && <h4>{title}</h4>}
            {content && <MarkdownContent content={content} />}
            {children}
          </Text>
          {image && <Img fixed={image.childImageSharp.fixed} />}
        </Content>
      </WidthWrapper>
    </Wrapper>
    
  )
}
