import styled from 'styled-components';
import { GREEN, PALE_GREEN, BLACK } from '../../style/colors';
import { Link } from '../Navbar/Navbar.style';
import { PADDING } from '../../style';

export const Wrapper = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  padding: ${PADDING}px;
  justify-content: space-between;

  ${Link}, h5, div {
    color: ${BLACK};
  }
`;

export const Address = styled.div`

`

export const Contact = styled.div`

`;