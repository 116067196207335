import React from "react"
import { graphql } from "gatsby"
import { Grid, Row } from 'react-flexbox-grid';

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import CMSLayout from '../components/CMSLayout';
import Section from '../components/Section';

import { Heading, Subheading, Benefits, Benefit } from '../style/home.style';
import { PADDING } from "../style";
import Sections from "../components/Sections";

export default function ReflexologyPage({
  data,
  cmsPreview,
}) {
  const { heading, subheading, image, sections, benefits, blur } = data.markdownRemark.frontmatter
  const Wrapper = cmsPreview ? CMSLayout : Layout;
  console.log(image)
  return (
    <Wrapper>
      <Hero image={image} blur={blur}>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
      </Hero>
      <Sections sections={sections} notLast />
      <Section>
        <Benefits>
          <h4 style={{ marginBottom: `${PADDING}px` }}>Benefits of reflexology</h4>
          <Grid fluid>
            <Row>
              {benefits.map(paragraph => <Benefit xs={12} md={6} lg={4}>{paragraph}</Benefit>)}
            </Row>
          </Grid>
        </Benefits>
      </Section>
      
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "reflexology" } }) {
      frontmatter {
        title
        heading
        subheading
        sections {
          title
          id
          reverse
          centered
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          background
          content
        }
        benefits
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blur
      }
    }
  }
`
