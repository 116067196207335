import React from "react"
import { graphql } from "gatsby"

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import CMSLayout from '../components/CMSLayout';
import Section from '../components/Section';

import { Heading, Subheading } from '../style/home.style';
import MarkdownContent from "../components/Markdown";
import Sections from "../components/Sections";

export default function AboutPage({
  data,
  cmsPreview,
}) {
  const { heading, subheading, image, sections, blur, boxsection } = data.markdownRemark.frontmatter
  const Wrapper = cmsPreview ? CMSLayout : Layout;
  return (
    <Wrapper>
      <Hero image={image} blur={blur}>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
      </Hero>
      <Sections sections={sections} notLast />
      <Section centered>
        {boxsection.map(line => <MarkdownContent content={line} />)}
      </Section>
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "about" } }) {
      frontmatter {
        heading
        subheading
        sections {
          id
          reverse
          centered
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          background
          content
        }
        boxsection
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blur
      }
    }
  }
`
