import React from 'react'

import { useSiteMeta } from '../../hooks';
import { Wrapper, Address, Contact } from './Footer.style';
import { Link } from '../Navbar/Navbar.style';
import WidthWrapper from '../WidthWrapper';


export default function Footer(props) {
  const { title, phone, email, address } = useSiteMeta();

  return (
    <WidthWrapper>
      <Wrapper>
        <Address>
          <h5>{title}</h5>
          {address.map(line => <div>{line}</div>)}
        </Address>
        <Contact>
          <Link href={`tel:${phone}`}>{phone}</Link>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Contact>
      </Wrapper>
      
    </WidthWrapper>
  )
}
