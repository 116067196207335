// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reflexology-js": () => import("../src/pages/reflexology.js" /* webpackChunkName: "component---src-pages-reflexology-js" */),
  "component---src-pages-treatments-js": () => import("../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */)
}

