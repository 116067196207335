import React from "react"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet';

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import CMSLayout from '../components/CMSLayout';
import Section from '../components/Section';

import { Heading, Subheading } from '../style/home.style';
import Sections from "../components/Sections";

export default function BookingPage({
  data,
  cmsPreview,
}) {
  const { heading, subheading, image, sections, blur } = data.markdownRemark.frontmatter
  const Wrapper = cmsPreview ? CMSLayout : Layout;
  
  return (
    <Wrapper>
      <Hero image={image} blur={blur}>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
      </Hero>
      <Sections sections={sections} notLast />
      <Section>
        <div className="calendly-inline-widget" data-url="https://calendly.com/littleberries" style={{ minWidth: '320px', height: '706px', width: '100%' }}></div>
        <Helmet>
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
        </Helmet>
      </Section>
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "booking" } }) {
      frontmatter {
        title
        heading
        subheading
        sections {
          id
          reverse
          centered
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          background
          content
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blur
      }
    }
  }
`
